import React from "react";
// import { Link, graphql } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import about1 from "../images/about1.jpg";
import about2 from "../images/about2.jpg";
// import { FaBed, FaBath, FaWarehouse } from 'react-icons/fa';
import "../css/plans.css";

class AboutPage extends React.Component {
  render() {

    return (
      <Layout>
        <SEO title="About Us" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">

              <h1 style={{'margin-top': '30px'}}>Our Mission</h1>

            </div>
          </div>


          <div className="row mt-4 mb-4">
            <div className="col-sm">


            <p className="info-banner">
                At Heritage Craft Homes, we are committed to building high-quality residential properties that stand the test of time. Our mission is to provide our clients with exceptional craftsmanship, attention to detail, and personalized service. We strive to create homes that not only meet our clients' needs but exceed their expectations. With a focus on sustainability and innovation, we aim to build homes that are not only beautiful but also environmentally responsible. At Heritage Craft Homes, we are dedicated to creating homes that our clients will love for years to come.</p>
                

              <p>
                <img src={about1} className="rounded float-left mr-4 mb-2" alt="alt" />


                For new home construction services, Heritage Craft Homes has been the first call since 20 years in business. No job is too big or small and our friendly, skilled staff will be happy to help you with a long lasting solution. We are based in Ogden, and also provide our services in Weber County, Davis County and Box Elder County. We want to provide you with our services, and are committed to the highest standards of professionalism and we realize this is required to maintain happy return customers. This means that we maintain the accreditation, insurance, and licensing that provide you the protection and peace of mind that you deserve.</p>

              <p>The relationship we have developed with our customers is very personal, guiding you through the entire process, while looking out for your best interest, completing the job on time and within budget. We believe communication from design to final trim detail is key to building the home of your dreams. We can help you through each step of the process of building your dream home. We work closely with home designers and engineers on the design and planning of your home, and we also assist you in locating that special site for your custom home.</p>

              <p>As a general contractor, we oversee the construction of your home from start to finish, paying close attention to all the details along the way. To ensure our high standard of quality, we personally supervise each important step of the building process.</p>

              <p>We know that hiring the right builder can be a challenge. For this, you need a first class contractor and Heritage Craft Homes has the experience to make it all happen. After all, it’s your dream home and this is your opportunity to create something unique and memorable.</p>

              <p>
                <img src={about2} className="rounded float-right ml-4" alt="alt" />
                Heritage Craft Homes is committed to giving all of our customers the personalized service they expect, and a long lasting product they will enjoy for many years to come. We know that professional, high quality custom home building services can make a world of difference to the function of your home or business as well as avoiding you unnecessary costs. From start to finish, we are professional in every aspect of our work at Heritage Craft Homes.</p>

              <p>
                We know our success depends upon every project we complete to your satisfaction. We take a special pride in giving you the best service and quality work we can provide. We treasure our return customers and referrals, so our goal is to earn your trust by giving you exceptional service, the first time and every time.</p>

            </div>
          </div>

        </div>

      </Layout>
    )
  }
};

export default AboutPage;
